import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ReactDOM from 'react-dom';
import './styles.css';
import './scripts';
import Nav from './Nav';
import Home from './Home';
import FAQSection from './FAQSection';
import ServicesCarousel from './services';
import WhatsAppButton from './WhatsAppButton';
import ContactForm from './ContactForm';
import { SnackbarProvider } from 'notistack';
import Disclaimer from './Disclaimer';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <SnackbarProvider maxSnack={3}>
        <div className="main-content">
        <Nav />
          <Routes>
            <Route path="/" element={
              <>
                <Disclaimer />
                <Home />
                <ServicesCarousel />
                <WhatsAppButton phoneNumber="+918789384248" />
                <FAQSection />
              </>
            } />
            <Route path="/contact" element={<ContactForm />} />
          </Routes>
        </div>
      </SnackbarProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
