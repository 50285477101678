import './Nav.css';
import React, { useState } from 'react';
import logo from './Images/logo.png';
import { Link, useNavigate } from 'react-router-dom';

function Nav() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isServicesOpen, setIsServicesOpen] = useState(false);
    const navigate = useNavigate();

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
        setIsServicesOpen(false);
    };

    const toggleServices = () => {
        setIsServicesOpen(!isServicesOpen);
    };

    const handleNavigation = (path) => {
        navigate(path);
        setIsMenuOpen(false);  // Close the menu after navigation
    };
    return (
        <div className={`Navbar ${isMenuOpen ? 'menu-open' : ''}`}>
            <img src={logo} alt="insurance claim logo" className="insuranceClaimLogo"/>
            <button 
                className="menuToggle"
                onClick={toggleMenu}
            >
                {isMenuOpen ? '✕' : '☰'}
            </button>
            <ul className={`NavItems ${isMenuOpen ? 'show' : ''}`}>
                <li onClick={toggleMenu}><Link to="/" style={{ textDecoration: 'none', color: '#333333' }}>Home</Link></li>
                <li id='aboutUs'>About Us</li>
                <li id="servicesMenu"onClick={toggleServices}>
                    <a>Services</a><span className="dropdown-icon">&#9662;</span>
                    <ul onClick={() => handleNavigation('/contact')} id="dropdownMenu" className={`dropdown-menu ${isServicesOpen ? 'show' : ''}`}>
                        <li className="servicesDropDownMenuItems">Filing a complaint</li>
                        <li className="servicesDropDownMenuItems">Know your complaint status</li>
                        <li className="servicesDropDownMenuItems">Claim Pending/Denied</li>
                        <li className="servicesDropDownMenuItems">Claim Short Settled</li>
                        <li className="servicesDropDownMenuItems">Freelook Request Rejected</li>
                        <li className="servicesDropDownMenuItems">Mis-selling of a Policy</li>
                        <li className="servicesDropDownMenuItems">Surrender/Maturity Amount Not Paid/Less Paid</li>
                        <li className="servicesDropDownMenuItems">Understand your Policy</li>
                        <li className="servicesDropDownMenuItems">Fraud & Forgery</li>
                    </ul>
                </li>
                <li onClick={() => handleNavigation('/contact')} id="career-menu">Career</li>
                <li onClick={() => handleNavigation('/contact')} id="career-menu">Our Team</li>
                <li onClick={toggleMenu}><Link to="/contact" style={{ textDecoration: 'none', color: 'white' }}><button id="contactButton">Contact Us</button></Link></li>
            </ul>
        </div>
    )
}

export default Nav;