import React, { useState, useEffect } from 'react';
import './Disclaimer.css';  // Import the CSS file

const Disclaimer = () => {
  const [showDisclaimer, setShowDisclaimer] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem('disclaimer')) {
      setShowDisclaimer(true);
    }
  }, []);

  const handleAgree = () => {
    localStorage.setItem('disclaimer', 'agreed');
    setShowDisclaimer(false);
  };

  if (!showDisclaimer) return null;

  return (
    <div className="disclaimer-overlay">
      <div className="disclaimer-content">
        <h2 className="disclaimer-title">Disclaimer</h2>
        <div className="disclaimer-text">
          <p>As per the rules of the Bar Council of India, Claim Suvidha is not permitted to solicit work or advertise. By clicking "I Agree" below, you acknowledge the following:</p>
          <ul className="disclaimer-list">
            <li>There has been no advertisement, solicitation, or inducement to use our services.</li>
            <li>You are seeking information about us for your own use.</li>
            <li>Information provided is upon your request and does not create a lawyer-client relationship.</li>
            <li>Content on this website is for informational purposes only and should not be construed as legal advice.</li>
            <li>Claim Suvidha is not liable for any consequences of actions taken based on the information provided.</li>
            <li>For legal issues, please seek independent legal advice.</li>
          </ul>
        </div>
        <button 
          onClick={handleAgree}
          className="disclaimer-button"
        >
          I Agree
        </button>
      </div>
    </div>
  );
};

export default Disclaimer;