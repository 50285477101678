import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Home.css';
import hero_img from './Images/hero_img.png';
import legalImage from './Images/aboutSection.png';
import founderImage from './Images/founderImage.png'

function Home() {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate('/contact');
    };
    return (
        <div>
            <div className="heroSection">
                <div className="heroContent">
                    <div className="heroText">
                        <h1 id='heroHeading'>Managing to resolve your Insurance Claims & Grievances</h1>
                        <p className="heroSubtext">We're here to help you navigate the complex world of insurance claims.</p>
                        <button className="ctaButton" onClick={handleClick}>Register a Complaint</button>
                    </div>
                    <div className="heroImageContainer">
                        <img src={hero_img} alt="Insurance claim representation" className="heroImg" />
                    </div>
                </div>
            </div>
            <section className="aboutSection">
                <div className="aboutContent">
                    <div className="aboutText">
                        <h2 className="visionHeading">Our Vision</h2>
                        <p>
                            At CLAIM SUVIDHA, our vision is to be the foremost advocate and trusted
                            partner for individuals and businesses navigating the insurance claims
                            process. We aim to revolutionize the industry by providing unparalleled
                            support, transparency, and expertise, ensuring that every client receives fair
                            and timely compensation.
                        </p>
                        <p>
                            But we believe that the best legal representation requires more than that. We
                            believe that a personal touch to each Claim leads to better service and
                            bigger settlements. If you have questions regarding your claim, your case,
                            or the legal process, and want to consult directly with our Founder, Ms.
                            Preeti Chaturvedi, she will be the one to take your call – not a paralegal, not
                            a case manager, and not an associate.
                        </p>
                        <p>
                            With Claim Suvidha, you can count on professional, compassionate, and
                            reliable support at every step of your insurance claims journey. Our goal is to
                            ensure that you receive the compensation you deserve with minimal hassle.
                        </p>
                    </div>
                    <div className="aboutImageContainer">
                        <img src={legalImage} alt="Legal representation" className="aboutImage" />
                    </div>
                </div>
            </section>
            <section className="founderSection" id='about-us-section'>
                <div className="founderContent">
                    <div className="founderImageContainer">
                        <img src={founderImage} alt="Preeti Chaturvedi" className="founderImage" />
                    </div>
                    <div className="founderText">
                        <h2>Message from the Founder & Head Legal</h2>
                        <p className='founderInfo'>
                        <br />Dear Valued Clients,<br /><br />I am delighted to introduce Claim Suvidha, a legal consultancy dedicated to helping you navigate and resolve insurance claims disputes with confidence and ease. As the founder of this initiative, my vision is to create a platform where fairness, transparency, and expert guidance are paramount in securing the justice you deserve.<br /><br />Our mission at Claim Suvidha is to provide comprehensive legal support, tailored specifically to address the complexities of insurance claims. Whether you are facing delays, denials, or any other challenges in your claim process, our team of experienced professionals is here to advocate for your rights and ensure a fair resolution.
<br /><br />We understand the stress and uncertainty that often accompany insurance disputes. That is why we are committed to offering personalized services that not only address your immediate needs but also empower you with the knowledge and confidence to handle future situations. Our approach combines legal expertise with compassionate support, ensuring that you feel heard, understood, and supported every step of the way.<br /><br />
At Claim Suvidha, we believe that everyone deserves access to justice, and we are dedicated to making that a reality for you. Thank you for trusting us with your legal needs. We look forward to serving you and making a positive impact in your life.<br /><br />

Warm regards,<br /><br />

Preeti Chaturvedi <br />
Founder & Head Legal, Claim Suvidha
                        </p>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Home;